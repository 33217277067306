export interface MessageModel {
  message: string;
  ['send-date']: number;
  ['user-id']: string;
  id: string;
  topicid: string;
  [key: string]: string | number;
}

export enum MessageColumns {
  MESSAGE = 'message',
  SEND_DATE = 'send-date',
  USER_ID = 'user-id',
  MESSAGE_ID = 'id',
  TOPIC_ID = 'topicid',
  RESEND = 'resend',
}

export interface CurrentTopicsModel {
  ip: string; // websocket
  payload: string; // most recent message
  payloadsize: number;
  pk: string;
  qos: number;
  time: number;
  topicid: string; // message topic
  userid: string; // user who sent it
}

export enum CurrentTopicsColumns {
  PAYLOAD = 'payload',
  PAYLOAD_SIZE = 'payloadsize',
  TIME = 'time',
  TOPIC_ID = 'topicid',
  USER_ID = 'userid',
  MESSAGE_ID = 'pk',
  RESEND = 'resend',
}

export interface DeleteMessageModel {
  ip: string;
  payload: string;
  payloadsize: number;
  pk: string;
  qos: number;
  time: number;
  topicid: string;
  userid: string;
}

export interface MessageConnectError {
  errorCode: number;
  errorMessage: string;
  invocationContext?: string;
}

export interface MessageConfig {
  userName?: string;
  password?: string;
  cleanSession?: boolean;
  hosts?: string[];
  ports?: number[];
  useSSL?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void;
  onFailure?: (err: MessageConnectError) => void;
}

export interface MessagingClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConnect: (data: any) => void;
  onConnectionLost: (err: MessageConnectError) => void;
  connect: (config: MessageConfig) => void;
  send: (topic: string, msg: string, num: 0, bool: false) => void;
}

export interface MessagingObject {
  user?: string;
  URI?: string[];
  systemKey?: string;
  systemSecret?: string;
  _qos?: number;
  client?: Paho.MQTT.Client;
}

export interface MessageHistoryManagementModel {
  enabled: boolean;
  expiration_age_seconds: number; // negative for disabled
  max_rows: number; // negative for disabled
  max_size_kb: number; // negative for disabled
}
