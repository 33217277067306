import { AssetColumnMetadata } from 'cb-utils/console-entity-models/columns';

export interface DeviceModel {
  cb_service_account?: boolean;
  cb_ttl_override?: number;
  cb_token?: string;
  allow_certificate_auth: boolean;
  allow_key_auth: boolean;
  certificate: string;
  created_date: number;
  description: string;
  device_key: string;
  enabled: boolean;
  last_active_date: number;
  name: string;
  state: string;
  system_key: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [other: string]: any;
}

export enum DEVICE_COLUMNS {
  cb_service_account = 'cb_service_account',
  cb_ttl_override = 'cb_ttl_override',
  cb_token = 'cb_token',
  allow_certificate_auth = 'allow_certificate_auth',
  allow_key_auth = 'allow_key_auth',
  certificate = 'certificate',
  created_date = 'created_date',
  description = 'description',
  device_key = 'device_key',
  enabled = 'enabled',
  last_active_date = 'last_active_date',
  name = 'name',
  state = 'state',
  system_key = 'system_key',
  type = 'type',
  salt = 'salt',
  keys = 'keys',
  active_key = 'active_key',
}

const showInCreateModal: DEVICE_COLUMNS[] = [
  DEVICE_COLUMNS.name,
  DEVICE_COLUMNS.description,
  DEVICE_COLUMNS.enabled,
  DEVICE_COLUMNS.type,
  DEVICE_COLUMNS.state,
  DEVICE_COLUMNS.cb_service_account,
  DEVICE_COLUMNS.cb_ttl_override,
  DEVICE_COLUMNS.allow_certificate_auth,
  DEVICE_COLUMNS.allow_key_auth,
];
const requiredInCreateModal: DEVICE_COLUMNS[] = [DEVICE_COLUMNS.name, DEVICE_COLUMNS.active_key];
const noUpdateAllowed: DEVICE_COLUMNS[] = [
  DEVICE_COLUMNS.name,
  DEVICE_COLUMNS.salt,
  DEVICE_COLUMNS.keys,
  DEVICE_COLUMNS.system_key,
  DEVICE_COLUMNS.last_active_date,
  DEVICE_COLUMNS.device_key,
  DEVICE_COLUMNS.created_date,
  DEVICE_COLUMNS.cb_token,
];

const hideFromUser: DEVICE_COLUMNS[] = [DEVICE_COLUMNS.salt, DEVICE_COLUMNS.keys];

const displayName: DEVICE_COLUMNS = DEVICE_COLUMNS.name;

export const DEVICE_COLUMN_METADATA: AssetColumnMetadata<DEVICE_COLUMNS> = {
  defaultColumns: Object.keys(DEVICE_COLUMNS) as DEVICE_COLUMNS[],
  showInCreateModal,
  requiredInCreateModal,
  noUpdateAllowed,
  displayName,
  hideFromUser,
};
