import { StyleVars } from 'cssOverrides/styleVarsTypes';
import brandLogoUrl from 'img/brand-logos/tpsc.png';
import defaultStyles from '../default/styleVars';

// Color Refs
const $white = '#fff';
const $gray_100 = '#f8f9fa';
const $gray_200 = '#e9ecef';
const $gray_300 = '#dee2e6';
const $gray_400 = '#ced4da';
const $gray_500 = '#adb5bd';
const $gray_600 = '#6c757d';
const $gray_700 = '#495057';
const $gray_800 = '#343a40';
const $gray_900 = '#212529';
const $black = '#000';

const $primary = '#4571b0';
const $secondary = '#900';

const $danger = '#b50500';

const $header_background = $primary;
const $header_text_color = $white;

const $success = '#28a745';

const $logo_color = $white;

const $loginButton = $secondary;
const $loginButtonFontColor = $black;
const $mainContainerFontColor = $white;
const $registrationFormWrapper = $black;
const $registrationFormHeaderFontColor = $white;
const $submitButton = $secondary;
const $submitButtonFontColor = $black;
const $platformOverviewContainer = $black;
const $platformOverviewFontColor = $white;
const $platformOverviewServicesFontColor = $secondary;
const $platformOverviewOutlineButton = $black;
const $platformOverviewOutlineButtonBorder = $secondary;
const $platformOverviewOutlineButtonFontColor = $secondary;
const $platformOverviewButton = $secondary;
const $platformOverviewButtonFontColor = $black;
const $ipmOverviewContainer = '#f0f0f0';
const $ipmOverviewButton = $secondary;
const $ipmOverviewButtonFontColor = $black;
const $developerResourcesContainer = $black;
const $developerResourcesFontColor = $white;
const $developerResourcesServicesFontColor = $secondary;
const $developerResourcesButton = $secondary;
const $developerResourcesButtonFontColor = $black;

const styles: StyleVars = {
  ...defaultStyles,
  brandLogoUrl,
  brandLogoWhiteUrl: brandLogoUrl,
  displayPoweredByClearBlade: true,
  productName: 'ClearBlade',

  primary: $primary,
  secondary: $secondary,
  success: $success,
  danger: $danger,

  white: $white,
  gray100: $gray_100,
  gray200: $gray_200,
  gray300: $gray_300,
  gray400: $gray_400,
  gray500: $gray_500,
  gray600: $gray_600,
  gray700: $gray_700,
  gray800: $gray_800,
  gray900: $gray_900,
  black: $black,
  logo_color: $logo_color,

  header_background: $header_background,
  header_text_color: $header_text_color,

  landing_page_loginButton: $loginButton,
  landing_page_loginButtonFontColor: $loginButtonFontColor,
  landing_page_mainContainerFontColor: $mainContainerFontColor,
  landing_page_registrationFormWrapper: $registrationFormWrapper,
  landing_page_registrationFormHeaderFontColor: $registrationFormHeaderFontColor,
  landing_page_submitButton: $submitButton,
  landing_page_submitButtonFontColor: $submitButtonFontColor,
  landing_page_platformOverviewContainer: $platformOverviewContainer,
  landing_page_platformOverviewFontColor: $platformOverviewFontColor,
  landing_page_platformOverviewServicesFontColor: $platformOverviewServicesFontColor,
  landing_page_platformOverviewOutlineButton: $platformOverviewOutlineButton,
  landing_page_platformOverviewOutlineButtonBorder: $platformOverviewOutlineButtonBorder,
  landing_page_platformOverviewOutlineButtonFontColor: $platformOverviewOutlineButtonFontColor,
  landing_page_platformOverviewButton: $platformOverviewButton,
  landing_page_platformOverviewButtonFontColor: $platformOverviewButtonFontColor,
  landing_page_ipmOverviewContainer: $ipmOverviewContainer,
  landing_page_ipmOverviewButton: $ipmOverviewButton,
  landing_page_ipmOverviewButtonFontColor: $ipmOverviewButtonFontColor,
  landing_page_developerResourcesContainer: $developerResourcesContainer,
  landing_page_developerResourcesFontColor: $developerResourcesFontColor,
  landing_page_developerResourcesServicesFontColor: $developerResourcesServicesFontColor,
  landing_page_developerResourcesButton: $developerResourcesButton,
  landing_page_developerResourcesButtonFontColor: $developerResourcesButtonFontColor,
};

export default styles;
