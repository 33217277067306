/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'bootstrap/dist/css/bootstrap.css';
import 'core-js';
import { Router } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { reportError } from './cb-utils/reportError';
// eslint-disable-next-line no-constant-condition
if (true) {
  window.onerror = (msg, _file, _line, _col, error) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reportError(msg.toString(), error);
  };
}

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon, the manifest.json file and the .htaccess file
import 'file-loader?name=[name].[ext]!./.htaccess';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import { history } from './appHistory';
import store from './appStore';
import { GlobalStyle } from './global-styles';

import { appQueryClient } from 'appQueryClient';
import { AntDGlobalStyle } from 'cssOverrides/antdOverrides';
import 'cssOverrides/bootstrap';

const MOUNT_NODE = document.getElementById('app');

const render = (messages: typeof translationMessages) => {
  ReactDOM.render(
    <QueryClientProvider client={appQueryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <GlobalStyle />
          <AntDGlobalStyle />
          <Router history={history}>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore */}
            <App />
          </Router>
        </LanguageProvider>
      </Provider>
    </QueryClientProvider>,
    MOUNT_NODE,
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App', './global-styles'], () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'intl/locale-data/jsonp/en.js'
        ),
      ]),
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
