import { DeployAssetStatusError, SyncEventType } from 'cb-utils/services/deployment';
import deploymentMessages from 'containers/Console/messages';
import { AssetStatusWithSyncAndDeployErrors, EdgesWithAssetStatusMap } from 'containers/Console/state';
import uuid from 'uuid';

export function isDeployAssetClass(
  item: AssetStatusWithSyncAndDeployErrors | AssetStatusWithSyncAndDeployErrors[],
): item is AssetStatusWithSyncAndDeployErrors[] {
  return Array.isArray(item);
}

export function itemHasErrors(
  item: AssetStatusWithSyncAndDeployErrors | AssetStatusWithSyncAndDeployErrors[],
  forSyncStatus: boolean,
) {
  if (isDeployAssetClass(item)) {
    return item.some((i) => i[forSyncStatus ? 'syncErrors' : 'deployErrors']?.length);
  } else {
    return item[forSyncStatus ? 'syncErrors' : 'deployErrors']?.length;
  }
}

export function formatErrorString(assetClass: string, assetId: string, error: DeployAssetStatusError) {
  return `Error syncing ${assetClass} ${assetId ? `"${assetId}"` : ''} to "${error.destination}": ${error.message}`;
}

export interface FormattedError {
  class: string;
  id: string;
  timestamp: number;
  msg: string;
  uuid: string;
  event: SyncEventType;
}

export function aggregateErrorDataEdge(
  assets: AssetStatusWithSyncAndDeployErrors[],
  forSyncStatus: boolean,
): FormattedError[] {
  return assets.reduce((errors, asset) => {
    const errorsByType = forSyncStatus ? asset.syncErrors : asset.deployErrors;
    if (errorsByType?.length) {
      errors.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...errorsByType.map((e) => ({
          timestamp: e.timestamp,
          class: asset.asset_class,
          id: asset.asset_id,
          msg: formatErrorString(asset.asset_class, asset.asset_id, e),
          uuid: uuid(),
          event: e.sync_event,
        })),
      );
    }
    return errors;
  }, []);
}

export function aggregateErrorDataDeployment(
  edges: EdgesWithAssetStatusMap | undefined,
  forSyncStatus: boolean,
  edgeName?: string,
) {
  const failedEdges: EdgesWithAssetStatusMap = {};
  const listOfErrors: FormattedError[] = [];
  if (edges) {
    if (edgeName) {
      const errors = aggregateErrorDataEdge(edges[edgeName], forSyncStatus);
      if (errors.length) {
        failedEdges[edgeName] = edges[edgeName];
        listOfErrors.push(...errors);
      }
    } else {
      for (const edge in edges) {
        const errors = aggregateErrorDataEdge(edges[edge], forSyncStatus);
        if (errors.length) {
          failedEdges[edge] = edges[edge];
          listOfErrors.push(...errors);
        }
      }
    }
  }

  return {
    failedEdges,
    listOfErrors,
  };
}

export function getAssetClassLabel(assetClass: string): {
  id: string;
  defaultMessage: string;
} {
  switch (assetClass) {
    case 'services':
      return deploymentMessages.assetClassServices;
    case 'portals':
      return deploymentMessages.assetClassPortals;
    case 'libraries':
      return deploymentMessages.assetClassLibraries;
    case 'triggers':
      return deploymentMessages.assetClassTriggers;
    case 'timers':
      return deploymentMessages.assetClassTimers;
    case 'roles':
      return deploymentMessages.assetClassRoles;
    case 'users':
      return deploymentMessages.assetClassUsers;
    case 'devices':
      return deploymentMessages.assetClassDevices;
    case 'collections':
      return deploymentMessages.assetClassCollections;
    case 'adaptors':
      return deploymentMessages.assetClassAdapters;
    case 'plugins':
      return deploymentMessages.assetClassPlugins;
    case 'servicecaches':
      return deploymentMessages.assetClassServiceCache;
    case 'webhooks':
      return deploymentMessages.assetClassWebhooks;
    case 'bucketsets':
      return deploymentMessages.assetClassFiles;
    case 'usersecrets':
      return deploymentMessages.assetClassSecrets;
    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return;
  }
}

const standardClasses = [
  'services',
  'portals',
  'libraries',
  'triggers',
  'timers',
  'roles',
  'users',
  'devices',
  'adaptors',
  'plugins',
  'servicecaches',
  'webhooks',
  'bucketsets',
  'usersecrets',
];

export function isCollection(assetClass: string) {
  return standardClasses.indexOf(assetClass) === -1;
}

export function groupCollections(assetClasses: EdgesWithAssetStatusMap) {
  for (const assetClass in assetClasses) {
    if (isCollection(assetClass)) {
      if (!assetClasses.collections) {
        assetClasses.collections = [];
      }
      assetClasses.collections.push(...assetClasses[assetClass]);
      delete assetClasses[assetClass];
    }
  }
  return assetClasses;
}
