import CodeService from '../AlertTypes/codeService';
import Email from '../AlertTypes/email';
import SMS from '../AlertTypes/sms';
import { AlertDefinition, AvailableAlertTypes } from './types';
import { Alert, ProviderCodeInfo, ProviderCodeGenerator } from '../types';
import { getProviderTypeByName } from '../AlertProviders';
import { QUOTE_TYPE, createAlertCaller } from '../serviceConstants';
import { SettingTypes, TypedSettingDefinition } from 'utils/types';
import { AnyMap } from '../../console-entity-models';

const AlertTypes: Array<AlertDefinition<{}>> = [CodeService, Email, SMS];

export const getAlertTypeByName = (name: AvailableAlertTypes) => {
  return AlertTypes.filter((alert) => alert.name === name)[0];
};

export const getFirstProviderForAlertType = (name: AvailableAlertTypes) => getAlertTypeByName(name).providers[0];

export const createAlertCodeForRule = (alerts: Alert[], ruleName: string) => {
  const providerCodeInfo: Array<ProviderCodeInfo<{}, {}>> = [];
  alerts.forEach((a) => {
    const alertDef = getAlertTypeByName(a.type);
    const providerDef = getProviderTypeByName(a.provider);
    providerCodeInfo.push({
      providerDef,
      alertFields: formatFields(alertDef.fields, a.alertFields),
      providerFields: formatFields(providerDef.fields, a.providerFields),
    });
  });

  return providerCodeInfo.reduce((red, info, i, arr) => {
    const gen: ProviderCodeGenerator<{}, {}> = {
      ...info,
      idx: i,
      array: arr,
    };
    return `${red}
    
    function ${createAlertCaller(i)} () {
      ${gen.providerDef.createProviderCode(ruleName, gen)}
    }
    `;
  }, '');
};

const formatFields = (definitions: TypedSettingDefinition[], fields: AnyMap) => {
  const rtn: AnyMap = {};
  definitions.forEach((def) => {
    switch (def.type) {
      case SettingTypes.PARAMETERIZED_TEXT_AREA_TYPE:
        rtn[def.name] = formatFieldParameter(fields[def.name]);
        break;
      case SettingTypes.JSON_BUILDER_TYPE:
      case SettingTypes.ARRAY_BUILDER_TYPE: {
        const field = fields[def.name];
        const fieldToUse = field ? field : {};
        rtn[def.name] = JSON.stringify(fieldToUse);
        break;
      }
      default:
        rtn[def.name] = fields[def.name];
        break;
    }
  });
  return rtn;
};

// todo: write a test for this
const re = new RegExp(QUOTE_TYPE, 'g');
// this function is responsible for replacing {{ and }} with " + and + ", respectively
const formatFieldParameter = (val: string) => {
  if (val) {
    const formattedVal = val.replace(re, `\\${QUOTE_TYPE}`);
    return formattedVal.replace(/{{/g, `${QUOTE_TYPE} + `).replace(/}}/g, ` + ${QUOTE_TYPE}`).replace(/\n/g, ''); // not really sure how we should handle newlines, so just remove them for now
  } else {
    return '';
  }
};

export default AlertTypes;
