import { Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import ErrorModal from 'components/ErrorModal';
import Cookies from 'js-cookie';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { useOIDCCallbackQuery } from '../api/oidcCallback';
import messages from '../messages';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[800],
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '32px',
  },
  text: {
    fontSize: '23px',
    fontWeight: 400,
    marginTop: '48px',
  },
}));

export const OIDCCallbackPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const classes = useStyles();
  const oidcCallbackQuery = useOIDCCallbackQuery(
    { code: code ?? undefined, state: state ?? undefined },
    {
      onSuccess: (data) => {
        const searchParams = new URLSearchParams();
        Object.keys(data).forEach((key) => {
          const val = data[key];
          if (typeof val === 'string') {
            searchParams.set(key, val);
          }
        });

        const requestOriginUrl = Cookies.get('oidc-token-callback-url');
        window.location.href = `${requestOriginUrl}?${searchParams.toString()}`;
      },
    },
  );

  if (!code || !state) {
    return (
      <ErrorModal
        onClose={() => {
          history.push('/');
        }}
        title={'Error authenticating user'}
        body={'Unable to get state or code from the search parameters.'}
      />
    );
  }

  if (oidcCallbackQuery.isError) {
    return (
      <ErrorModal
        onClose={() => {
          history.push('/');
        }}
        title={'Error authenticating user'}
        body={oidcCallbackQuery.error}
      />
    );
  }

  return oidcCallbackQuery.isLoading ? (
    <>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={12} sm={12} md={12} />
        <Box className={classes.container}>
          <Box className={classes.content}>
            <CircularProgress size={85} thickness={2} />
            <Typography variant="h6" className={classes.text}>
              <FormattedMessage {...messages.loadingInfo} />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  ) : (
    <>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={12} sm={12} md={12} />
        <Box className={classes.container}>
          <Box className={classes.content}>
            <CircularProgress size={85} thickness={2} />
            <Typography variant="h6" className={classes.text}>
              <FormattedMessage {...messages.validatingInfo} />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
