/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { Reducer, combineReducers, Store } from 'redux';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import { State as LanguageState } from 'containers/LanguageProvider/state';
import { State as ConsoleState } from 'containers/Console/state';
import { STORE_KEY as ConsoleStoreKey } from 'containers/Console/constants';
import { Map } from 'cb-utils/console-entity-models';
import { State as AppReducerState } from 'containers/App/reducer';
import { State as PortalState } from 'containers/Portal/reducer';
import { State as CbPortalState } from 'containers/CbPortal/reducer';
import { State as SystemsPageState } from 'containers/SystemsPage/reducer';
import { State as ServiceDetailPageState } from 'containers/ServiceDetailPage/reducer';
import { State as RolesPageState } from 'containers/RolesPage/reducer';
import { State as RoleDetailState } from 'containers/RoleDetail/reducer';
import { State as PortalEditModalState } from 'containers/PortalEditModal/reducer';
import { State as NewEntityModalState } from 'containers/NewEntityModal/reducer';
import { State as MessagesPageState } from 'containers/MessagesPage/reducer';
import { State as MessagesDetailState } from 'containers/MessagesDetail/reducer';
import { State as MessageQueryContainerState } from 'containers/MessageQueryContainer/reducer';
import { State as LiveMessageContainerState } from 'containers/LiveMessageContainer/reducer';
import { State as ExportSystemFormState } from 'containers/ExportSystemForm/reducer';
import { State as IpmPackageDetailState } from 'containers/IpmPackageDetail/reducer';
import { State as ExampleTSContainerState } from 'containers/ExampleTSContainer/reducer';
import { State as DeploymentItemLevelViewWrapperState } from 'containers/DeploymentItemLevelViewWrapper/reducer';
import { State as DeploymentItemLevelViewState } from 'containers/DeploymentItemLevelView/reducer';
import { State as DeploymentAssetManagerState } from 'containers/DeploymentAssetManager/reducer';
import { State as DeployDetailState } from 'containers/DeployDetail/reducer';
import { State as DeployState } from 'containers/Deploy/reducer';
import { State as AdaptersPageState } from 'containers/AdaptersPage/reducer';
import { State as AdapterEdgeManagerState } from 'containers/AdapterEdgeManager/reducer';
import { State as AdapterDetailState } from 'containers/AdapterDetail/reducer';
import { State as AdapterConfigModalState } from 'containers/AdapterConfigModal/reducer';
import { State as CbPaneGridContainerState } from 'containers/CbPaneGridContainer/reducer';
import { State as PortalWrapperState } from 'containers/PortalWrapper/reducer';
import { State as CbFlyoutPaneContainerState } from 'containers/CbFlyoutPaneContainer/reducer';
import { State as CbHeaderContainerState } from 'containers/CbHeaderContainer/reducer';
import { State as CbThemeEditorContainerState } from 'containers/CbThemeEditorContainer/reducer';
import { State as PortalDevConsoleSideBarState } from 'containers/PortalDevConsoleSideBar/reducer';
import { State as AdminLicenseManagementState } from 'containers/AdminLicenseManagement/reducer';
import { PortalUserModalLayoutInfo } from 'containers/Portal/types';
import { FORMAT_KEY } from 'containers/Console/formatConstants';
import { FormatState } from 'containers/Console/reducers/format';

// todo: fill this out as needed
export interface AppState {
  language?: LanguageState;
  [ConsoleStoreKey]?: ConsoleState;
  [FORMAT_KEY]?: FormatState;
  portal?: PortalState;
  cbApp?: AppReducerState;
  cbPortal?: CbPortalState;
  SystemsPage?: SystemsPageState;
  ServiceDetailPage?: ServiceDetailPageState;
  RolesPage?: RolesPageState;
  RoleDetail?: RoleDetailState;
  PortalEditModal?: PortalEditModalState;
  NewEntityModal?: NewEntityModalState;
  MessagesPage?: MessagesPageState;
  MessagesDetail?: MessagesDetailState;
  MessageQueryContainer?: MessageQueryContainerState;
  LiveMessageContainer?: LiveMessageContainerState;
  ExportSystemForm?: ExportSystemFormState;
  IpmPackageDetail?: IpmPackageDetailState;
  ExampleTSContainer?: ExampleTSContainerState;
  DeploymentItemLevelViewWrapper?: DeploymentItemLevelViewWrapperState;
  DeploymentItemLevelView?: DeploymentItemLevelViewState;
  DeploymentAssetManager?: DeploymentAssetManagerState;
  DeploymentDetail?: DeployDetailState;
  deploy?: DeployState;
  AdaptersPage?: AdaptersPageState;
  AdapterEdgeManager?: AdapterEdgeManagerState;
  AdapterDetail?: AdapterDetailState;
  AdapterConfigModal?: AdapterConfigModalState;
  paneGridContainer?: CbPaneGridContainerState;
  portalWrapper?: PortalWrapperState;
  fyoutPaneContainer?: CbFlyoutPaneContainerState;
  headerContainer?: CbHeaderContainerState;
  CbUserModalContainer?: PortalUserModalLayoutInfo;
  themeEditor?: CbThemeEditorContainerState;
  PortalDevConsoleSideBar?: PortalDevConsoleSideBarState;
  AdminLicenseManagement?: AdminLicenseManagementState;
}

export type AppStore = Store<AppState>;

/**
 * Creates the main reducer with the dynamically injected ones
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createReducer(injectedReducers: Map<Reducer<any>>) {
  return combineReducers({
    language: languageProviderReducer,
    ...injectedReducers,
  });
}
