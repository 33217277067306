import isFunction from 'lodash/isFunction';
import isEqual from 'lodash/isEqual';

import Plugin, { ThisForDatasourceParser, SetupOptions } from '../plugin/PluginModel';
import {
  CALL_ON_LOAD,
  REFRESH_INTERVAL,
  SUPPRESS_DATASOURCE_ERRORS,
  DATASOURCE_PARSER,
} from '../../../containers/Portal/constants';
import secToMs from '../../secToMs';
import PluginPortalModel from '../PluginPortalModel';
import { SettingsInstance, DatasourceDefinition, SettingTypes } from 'utils/types';
import { AnyMap } from '../../console-entity-models';
import { DEFAULT_DATASOURCE_SETTINGS } from 'containers/Portal/pluginSettingUtils';
import { DatasourceTypes } from 'plugins/datasources/types';
import Parsers from '../plugin/utils/Parsers';
import { tempDsName } from '../constants';

const datasourceInstance = new WeakMap();

export type SerializedDatasourceInfo = DatasourceInfo & {
  lastUpdated: string;
};

export interface DatasourceInfo {
  id: string;
  name: string;
  settings: SettingsInstance;
  type: DatasourceTypes;
  didLastUpdateError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastDataSent?: any;
}

export interface DatasourceData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevData: any;
}

class DatasourceModel extends Plugin<{}> {
  refreshTimer: NodeJS.Timer;
  didLastUpdateError?: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastDataSent?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incomingData: any;
  rootDatasourceId: string;
  constructor(id: string, info: DatasourceInfo, portalModel: PluginPortalModel) {
    super(id, info.name, info.settings, portalModel);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.refreshTimer = null;
    this.incomingData = null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.rootDatasourceId = null;
  }

  clearRefreshTimer() {
    clearInterval(this.refreshTimer);
  }

  configureRefreshTimer() {
    const refreshSetting = this.getSettingByName(REFRESH_INTERVAL);
    if (refreshSetting !== undefined && refreshSetting > 0) {
      this.refreshTimer = setInterval(() => {
        this.refresh();
      }, secToMs(refreshSetting));
    }
  }

  editSettings(settings: AnyMap, name: string) {
    this.name = name;
    this.parsers = new Parsers();
    this.initializeNewParser(settings[DATASOURCE_PARSER]);
    super.edit(settings);
  }

  initializeNewParser = (value: string) => {
    this.createParser(
      { name: DATASOURCE_PARSER, type: SettingTypes.DATA_SETTING_TYPE },
      {
        value,
        isDebugOn: false,
      },
    );
  };

  async setUp(
    def: DatasourceDefinition,
    settings?: AnyMap,
    options: SetupOptions & { rootDatasourceId: string } = {
      rootDatasourceId: '',
    },
  ) {
    datasourceInstance.delete(this);
    const parserValue = settings ? settings[DATASOURCE_PARSER] : this.getSettingByName(DATASOURCE_PARSER);
    // need to initialize parser before setup because super.setup calls updateCallback in localVar and Agg ds
    this.initializeNewParser(parserValue);
    this.rootDatasourceId = options.rootDatasourceId;
    await super.setUp(def, settings);
    // instantiate the datasource plugin
    datasourceInstance.set(this, new def.class(this.settings(), this.updateCallback, this.errorCallback));
    this.dispatchUpdate(Date.now());
    const callOnLoadSetting = this.getSettingByName(CALL_ON_LOAD);
    if (callOnLoadSetting === undefined || callOnLoadSetting === true) {
      // if the setting is undefined, that probably means the datasource existed before this setting; we want to default to calling on load
      this.refresh(); // call updateNow on load
      this.configureRefreshTimer();
    }
    this.portalModel.updateAggregateDatasources(this.id);
  }

  getThisForDatasourceParser = () => {
    return {
      data: this.incomingData,
      model: this,
    } as ThisForDatasourceParser;
  };

  getIncomingData = () => {
    return this.incomingData;
  };

  updateCallback = (payload: UpdateCallbackData) => {
    this.lastUpdated = new Date();
    this.didLastUpdateError = false;
    if (!this.incomingData && !!this.rootDatasourceId) {
      // only for temporary datasources
      const rootDs = this.portalModel.getDatasourceInstanceById(this.rootDatasourceId);
      this.incomingData = rootDs.getIncomingData();
    } else {
      this.incomingData = payload;
    }
    let parsedData;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.parsers && this.settings().USE_PARSER) {
      try {
        parsedData = this.executeParser(DATASOURCE_PARSER, this.getThisForDatasourceParser());
      } catch (e) {
        parsedData = this.incomingData;
      }
    } else {
      parsedData = this.incomingData;
    }
    this.latestData(parsedData);
    this.dispatchUpdate(this.lastUpdated.getTime());
  };

  errorCallback = (title: string, msg: ErrorMessage) => {
    this.didLastUpdateError = true;
    this.dispatchUpdate(Date.now());
    const shouldSuppressError = this.getSettingByName(SUPPRESS_DATASOURCE_ERRORS) || !navigator.onLine;
    if (!shouldSuppressError) {
      let titleStr = title;
      if (this.id !== tempDsName) {
        titleStr = `${title} "${this.name}"`;
      }
      this.portalModel.dispatcher.handleDatasourceError({
        id: this.id,
        title: titleStr,
        error: msg,
        collapsedText:
          this.lastDataSent !== undefined
            ? `Called with: \n\n${JSON.stringify(this.lastDataSent, null, 2)}`
            : undefined,
      });
    }
  };

  processUpdateToSettings() {
    // refresh to apply new/parsed data
    this.refresh();
    return {};
  }

  settingsChanged() {
    const ds = datasourceInstance.get(this);
    // set the settings value for the datasource instance
    // any datasource that extends from DatasourceClass will be able
    // to use 'this.settings' to get the current value for settings
    if (ds) {
      // check since temp datasources won't exist in datasourceInstance yet
      ds.settings = this.settings();
      this.dispatchUpdate(Date.now());
      this.clearRefreshTimer();
      this.configureRefreshTimer();

      if (this.didNonDefaultSettingChange()) {
        this.callInstanceMethod('onSettingsChanged', this.settings(), this.settings.getPreviousValue());
      }
    }
  }

  // checks to see if there's a difference between current and previous settings
  // will return true if there was a change in a setting that IS NOT a "default setting"
  didNonDefaultSettingChange(): boolean {
    const newSettings = this.settings();
    const oldSettings = this.settings.getPreviousValue();
    let shouldCallInstance = false;
    for (const settingName in newSettings) {
      if (Object.prototype.hasOwnProperty.call(newSettings, settingName)) {
        if (
          DEFAULT_DATASOURCE_SETTINGS.indexOf(settingName) === -1 &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !isEqual(newSettings[settingName], (oldSettings as AnyMap)[settingName])
        ) {
          shouldCallInstance = true;
        }
      }
    }
    return shouldCallInstance;
  }

  getName() {
    return this.name;
  }

  getSchema() {
    return this.callInstanceMethod('getSchema');
  }

  getCRUDSchema() {
    return this.callInstanceMethod('getCRUDSchema');
  }

  getModel(): this | {} {
    return this;
  }

  getCount() {
    return this.callInstanceMethod('getCount');
  }

  callInstanceMethod(method: string, ...args: InstanceMethodArgs) {
    if (datasourceInstance.get(this) && isFunction(datasourceInstance.get(this)[method])) {
      return datasourceInstance.get(this)[method](...args);
    }
  }

  refresh() {
    this.callInstanceMethod('updateNow');
  }

  delete() {
    this.clearRefreshTimer();
    try {
      this.callInstanceMethod('onDispose');
    } catch (e) {
      // currently the only datasource that uses this method is the message topic datasource. if messaging failed to connect (most likely due to invalid permissions) then onDispose will throw an error because it tries to unsubscribe
      // I don't think we should display this to the user as they just want to remove the datasource

      console.error(`Failed to run 'onDispose' for datasource with name '${this.name}';`, e);
    }
  }

  dispatchUpdate(time: number) {
    this.portalModel.dispatcher.datasourceDataUpdated(this.id, time);
  }

  sendData(data: DataFromUser) {
    this.lastDataSent = data;
    return this.callInstanceMethod('sendData', data);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataFromUser = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InstanceMethodArgs = any[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorMessage = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateCallbackData = any;

export default DatasourceModel;
