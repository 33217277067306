export enum STORAGE_OPTIONS {
  LOCAL = 'local',
  GOOGLE = 'google',
  S3 = 's3',
}

export interface GoogleBucketsStorageConfig {
  bucket_name: string;
  credentials: {
    type: string;
    project_id: string;
    private_key_id: string;
    private_key: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
  };
}

export interface LocalBucketsStorageConfig {
  root: string;
}

export interface S3BucketsStorageConfig {
  bucket_name: string;
  bucket_region: string;
  user_id: string;
  user_secret: string;
}

export type BucketConfigOption = GoogleBucketsStorageConfig | S3BucketsStorageConfig | LocalBucketsStorageConfig;

export interface BucketSetModel {
  name: string;
  platform_storage: STORAGE_OPTIONS;
  platform_config: BucketConfigOption;
  edge_storage: STORAGE_OPTIONS;
  edge_config: BucketConfigOption;
  system_key: string;
}

export type BucketSets = BucketSetModel[];
