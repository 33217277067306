/*
 * Console Messages
 *
 * This contains all the text for the Console component.
 */
import { defineMessages } from 'react-intl';

export const consoleMessages = defineMessages({
  header: {
    id: 'app.containers.Console.header',
    defaultMessage: 'This is Console container !',
  },
  deploy: {
    id: 'app.containers.Console.deploy',
    defaultMessage: 'Deploy',
  },
  syncToEdge: {
    id: 'app.containers.Console.syncToEdge',
    defaultMessage: 'Sync To Edge',
  },
  syncToPlatform: {
    id: 'app.containers.Console.syncToPlatform',
    defaultMessage: 'Sync To Platform',
  },
  assetClassCode: {
    id: 'app.components.DeploymentAssetManager.assetClassCode',
    defaultMessage: 'Code',
  },
  assetClassServices: {
    id: 'app.components.DeploymentAssetManager.assetClassServices',
    defaultMessage: 'Services',
  },
  assetClassServiceCache: {
    id: 'app.components.DeploymentAssetManager.assetClassServiceCache',
    defaultMessage: 'Shared Caches',
  },
  assetClassSecrets: {
    id: 'app.components.DeploymentAssetManager.assetClassSecrets',
    defaultMessage: 'Secrets',
  },
  assetClassPortals: {
    id: 'app.components.DeploymentAssetManager.assetClassPortals',
    defaultMessage: 'Portals',
  },
  assetClassPortals_help: {
    id: 'app.components.DeploymentAssetManager.assetClassPortals_help',
    defaultMessage:
      'Portals allow users to quickly create single instance of web applications that interact with and visualize an IoT system.',
  },
  assetClassLibraries: {
    id: 'app.components.DeploymentAssetManager.assetClassLibraries',
    defaultMessage: 'Libraries',
  },
  assetClassTriggers: {
    id: 'app.components.DeploymentAssetManager.assetClassTriggers',
    defaultMessage: 'Triggers',
  },
  assetClassTimers: {
    id: 'app.components.DeploymentAssetManager.assetClassTimers',
    defaultMessage: 'Timers',
  },
  assetClassWebhooks: {
    id: 'app.components.DeploymentAssetManager.assetClassWebhooks',
    defaultMessage: 'Webhooks',
  },
  assetClassRoles: {
    id: 'app.components.DeploymentAssetManager.assetClassRoles',
    defaultMessage: 'Roles',
  },
  assetClassRoles_help: {
    id: 'app.components.DeploymentAssetManager.assetClassRoles_help',
    defaultMessage:
      'Roles provide permissions and access to functionality of the assets. They can be assigned to users and devices. ClearBlade provides default roles and the ability to create custom roles.',
  },
  assetClassAdapters: {
    id: 'app.components.DeploymentAssetManager.assetClassAdapters',
    defaultMessage: 'Adapters',
  },
  assetClassAdapters_help: {
    id: 'app.components.DeploymentAssetManager.assetClassAdapters_help',
    defaultMessage:
      'An Adapter is a custom software component, deployed on gateway devices, whose purpose is to serve as a proxy between the platform and IoT devices that cannot directly communicate with the ClearBlade Platform.',
  },
  assetClassPlugins: {
    id: 'app.components.DeploymentAssetManager.assetClassPlugins',
    defaultMessage: 'Plugins',
  },
  assetClassUsers: {
    id: 'app.components.DeploymentAssetManager.assetClassUsers',
    defaultMessage: 'Users',
  },
  assetClassManageUsers: {
    id: 'app.components.DeploymentAssetManager.assetClassManageUsers',
    defaultMessage: 'User Management',
  },
  assetClassDevices: {
    id: 'app.components.DeploymentAssetManager.assetClassDevices',
    defaultMessage: 'Devices',
  },
  caCertificates: {
    id: 'app.components.DeploymentAssetManager.certificates',
    defaultMessage: 'CA Certificates',
  },
  assetClassCollections: {
    id: 'app.components.DeploymentAssetManager.assetClassCollections',
    defaultMessage: 'Collections',
  },
  assetClassExternalDatabases: {
    id: 'app.components.DeploymentAssetManager.assetClassExternalDatabases',
    defaultMessage: 'External Databases',
  },
  assetClassFiles: {
    id: 'app.components.DeploymentAssetManager.assetClassFiles',
    defaultMessage: 'Files',
  },
  assetClassFiles_help: {
    id: 'app.components.DeploymentAssetManager.assetClassFiles_help',
    defaultMessage:
      'Select a deployment to view its file storage. Files will always be organized into the following buckets: Inbox, Outbox, and Sandbox.',
  },
  assetClassDeployments: {
    id: 'app.components.DeploymentAssetManager.assetClassDeployments',
    defaultMessage: 'Deployments',
  },
  assetClassDeployments_help: {
    id: 'app.components.DeploymentAssetManager.assetClassDeployments_help',
    defaultMessage:
      'A deployment allows developers to define which assets or groups of assets run on particular edge instances. It also provides the ability to synchronize assets between the platform and the edge',
  },
  assetClassEdges: {
    id: 'app.components.DeploymentAssetManager.assetClassEdges',
    defaultMessage: 'Edges',
  },
  sessions: {
    id: 'app.components.DeploymentAssetManager.sessions',
    defaultMessage: 'Sessions',
  },
  sessions_help: {
    id: 'app.components.DeploymentAssetManager.sessions_help',
    defaultMessage: 'A log of active sessions for users and devices.',
  },
  databaseConsole: {
    id: 'app.components.DeploymentAssetManager.databaseConsole',
    defaultMessage: 'Database Console',
  },
  databaseConsole_help: {
    id: 'app.components.DeploymentAssetManager.databaseConsole_help',
    defaultMessage:
      'A console for running raw queries against collections, as well as the users, devices, and edges tables',
  },
  assetClassMessageHistory: {
    id: 'app.components.DeploymentAssetManager.assetClassMessageHistory',
    defaultMessage: 'Message History',
  },
  assetClassSystems: {
    id: 'app.components.DeploymentAssetManager.assetClassSystems',
    defaultMessage: 'Systems',
  },
  assetClassSystems_help: {
    id: 'app.components.DeploymentAssetManager.assetClassSystems_help',
    defaultMessage:
      'A system represents the encapsulation for all the logic in your IoT solution. Create a system to begin using ClearBlade',
  },
  assetClassMessages: {
    id: 'app.components.MessagesPage.messages',
    defaultMessage: 'Messages',
  },
  messageTopics: {
    id: 'app.components.RoleDetailPage.messageTopics',
    defaultMessage: 'Message Topics',
  },
  readPermission: {
    id: 'app.components.SimplePermissionsCard.read',
    defaultMessage: 'Read',
  },
  createPermission: {
    id: 'app.components.SimplePermissionsCard.create',
    defaultMessage: 'Create',
  },
  updatePermission: {
    id: 'app.components.SimplePermissionsCard.update',
    defaultMessage: 'Update',
  },
  deletePermission: {
    id: 'app.components.SimplePermissionsCard.delete',
    defaultMessage: 'Delete',
  },
  runPermission: {
    id: 'app.components.SimplePermissionsCard.run',
    defaultMessage: 'Run',
  },
  executePermission: {
    id: 'app.components.SimplePermissionsCard.execute',
    defaultMessage: 'Execute',
  },
  subscribePermission: {
    id: 'app.components.SimplePermissionsCard.subscribe',
    defaultMessage: 'Subscribe',
  },
  publishPermission: {
    id: 'app.components.SimplePermissionsCard.publish',
    defaultMessage: 'Publish',
  },
  updatePasswordsPermission: {
    id: 'app.components.SimplePermissionsCard.updatePasswords',
    defaultMessage: 'Update Passwords',
  },
  addRemoveRolesOnUsersPermission: {
    id: 'app.components.SimplePermissionsCard.addRemoveRolesOnUsers',
    defaultMessage: `Update a User's Roles`,
  },
  allPermissions: {
    id: 'app.containers.Console.allPermissions',
    defaultMessage: 'All',
  },
  name: {
    id: 'app.containers.Console.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'app.containers.console.description',
    defaultMessage: 'Description',
  },
  architecture: {
    id: 'app.containers.AdaptersPage.architecture',
    defaultMessage: 'Architecture',
  },
  discardChanges: {
    id: 'app.containers.Console.discardChanges',
    defaultMessage: 'Discard changes',
  },
  saveAndExit: {
    id: 'app.containers.Console.saveAndExit',
    defaultMessage: 'Save and exit',
  },
  errorNameRequired: {
    id: 'app.components.NewEdgeModal.errorNameRequired',
    defaultMessage: 'Required',
  },
  errorSpaces: {
    id: 'app.components.NewEdgeModal.errorSpaces',
    defaultMessage: 'Cannot have spaces',
  },
  errorSpecialCharacters: {
    id: 'app.components.NewEdgeModal.errorSpecialCharacters',
    defaultMessage: 'Cannot have special characters',
  },
  about: {
    id: 'app.containers.Console.about',
    defaultMessage: 'About',
  },
  advanced: {
    id: 'app.containers.Console.advanced',
    defaultMessage: 'Advanced',
  },
  cancel: {
    id: 'app.containers.Console.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'app.containers.Console.close',
    defaultMessage: 'Close',
  },
  summary: {
    id: 'app.containers.Console.summary',
    defaultMessage: 'Summary: ',
  },
  ipmImportError: {
    id: 'app.containers.Console.ipmImportError',
    defaultMessage: 'Import Error',
  },
  warningTitle: {
    id: 'app.components.Ttlmanager.warningTitle',
    defaultMessage: 'Warning!',
  },
  deleteTokensQuestion: {
    id: 'app.components.Ttlmanager.deleteTokensQuestion',
    defaultMessage: 'Are you sure you want to delete these tokens?',
  },
  deleteTokensQuestionAll: {
    id: 'app.components.Ttlmanager.deleteTokensQuestion',
    defaultMessage: 'Are you sure you want to delete all {num} tokens?',
  },
  manageSessions: {
    id: 'app.containers.Console.manageSessions',
    defaultMessage: 'Manage sessions',
  },
  publicKeys: {
    id: 'app.containers.Console.publicKeys',
    defaultMessage: 'Public Keys',
  },
  setPermission: {
    id: 'app.components.PermissionsCardForTopics.setPermission',
    defaultMessage: 'Set',
  },
  getPermission: {
    id: 'app.components.PermissionsCardForTopics.getPermission',
    defaultMessage: 'Get',
  },
  auditTrail: {
    id: 'app.components.DeploymentAssetManager.auditTrail',
    defaultMessage: 'Audit Trail',
  },
  phoneNumberPlaceholder: {
    id: 'app.components.Console.phoneNumberPlaceholder',
    defaultMessage: '+11234567890',
  },
  notificationHistory: {
    id: 'app.components.Console.notificationHistory',
    defaultMessage: 'Notification History',
  },
  systemIsDisabled: {
    id: 'app.components.Console.systemIsDisabled',
    defaultMessage: 'System is disabled',
  },
  logs: {
    id: 'app.components.Console.logs',
    defaultMessage: 'Logs',
  },
});

export default consoleMessages;
