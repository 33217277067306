// eslint-disable-next-line @typescript-eslint/no-var-requires
const theme = require('./theme').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const themedStyleVars = require(`cssOverrides/${theme}/styleVars`).default;
import shadeColor, { darkPercentage, lightPercentage } from 'cb-utils/shadeColor';
import { StyleVars } from './styleVarsTypes';

const styleVars: Required<StyleVars> = {
  ...themedStyleVars,
  lightPrimary: themedStyleVars.lightPrimary || shadeColor(themedStyleVars.primary, lightPercentage),
  darkPrimary: themedStyleVars.darkPrimary || shadeColor(themedStyleVars.primary, darkPercentage),
};

export default styleVars;
