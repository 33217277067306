export interface PortalModel {
  config: string;
  description: string;
  last_updated: string | number;
  name: string;
  system_key: string;
  type: PortalTypeOptions;
  namespace?: string;
}

export enum PortalTypeOptions {
  CUSTOM = 'custom', // v1
  EDGE_MONITORING = 'edge_monitoring', // v1
  CLONE = 'clone',
  CUSTOM_V2 = 'custom_v2',
  EDGE_MONITORING_V2 = 'edge_monitoring_v2',
}

export const PORTAL_TYPE_OPTIONS: Array<{
  name: string;
  value: PortalTypeOptions;
}> = [
  { name: 'Custom', value: PortalTypeOptions.CUSTOM },
  { name: 'Edge Monitoring', value: PortalTypeOptions.EDGE_MONITORING },
  { name: 'Clone Existing Portal', value: PortalTypeOptions.CLONE },
];

export enum PortalVersionOptions {
  V1 = 'v1',
  V2 = 'v2',
}

export const PORTAL_VERSION_OPTIONS: Array<{
  name: string;
  value: PortalVersionOptions;
}> = [
  { name: 'V1', value: PortalVersionOptions.V1 },
  { name: 'V2', value: PortalVersionOptions.V2 },
];
