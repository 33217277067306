export enum VISIBILITY {
  GLOBAL = 'global',
  SYSTEM = 'system',
}

// const libraryExample = {
//   api: '',
//   code: '',
//   dependencies: '',
//   description: '',
//   library_key: 'log',
//   name: 'log',
//   system_key: '',
//   version: 1,
//   visibility: 'global',
// };

export interface LibraryModel {
  api?: string;
  code?: string;
  dependencies: string[];
  description?: string;
  library_key?: string;
  name: string;
  system_key: string;
  version: number;
  visibility: string;
  namespace?: string;
}

export interface BackendLibraryModel {
  api?: string;
  code?: string;
  dependencies: string;
  description?: string;
  library_key?: string;
  name: string;
  system_key: string;
  version: number;
  visibility: string;
  namespace?: string;
}
