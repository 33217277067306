/**
 *
 * ErrorBoundary
 *
 */

import React from 'react';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface State {
  error: boolean;
}

class ErrorBoundary extends React.Component<{}, State> {
  state: State = {
    error: false,
  };

  componentDidUpdate(prevProps: {}) {
    if (this.state.error && prevProps !== this.props) {
      // reset if we currently have an error; gives new children a chance to succeed - something everything needs :)
      this.setState({
        error: false,
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <h6>
          <FormattedMessage {...messages.unkownError} />
        </h6>
      );
    }
    return this.props.children;
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);

    console.error(errorInfo);

    this.setState({
      error: true,
    });
  }
}

export default ErrorBoundary;
