export interface AdapterCommandsInterface {
  deploy_command: string;
  logs_command: string;
  start_command: string;
  status_command: string;
  stop_command: string;
  undeploy_command: string;
}

export interface BaseAdapterModel {
  name: string;
  architecture: string;
  description: string;
  namespace?: string;
}

export interface AdapterUpdateModel extends AdapterCommandsInterface {
  os: string;
}

export interface AdapterModel extends BaseAdapterModel, AdapterUpdateModel {
  protocol: string;
  version: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface AdapterFileModel {
  adaptor_name: string;
  group: string;
  name: string;
  owner: string;
  path_name: string;
  permissions: string;
  version: number;
}

export interface AdapterFileFetchModel extends AdapterFileModel {
  file: string;
}

export interface AdapterFileReplaceModel {
  file?: string;
  name?: string;
}

export interface AdapterFileUploadModel extends AdapterFileReplaceModel {
  adaptor_name?: string;
  path_name?: string;
  command_name?: keyof AdapterCommandsInterface;
}

export enum AdapterCommands {
  deploy = 'deploy_command',
  start = 'start_command',
  stop = 'stop_command',
  status = 'status_command',
  logs = 'logs_command',
  undeploy = 'undeploy_command',
}

export enum AdapterFileTypes {
  old = 'old',
  new = 'new',
  replace = 'replace',
}

export type AdapterFileMap = { [keys in AdapterCommands]: string };

export interface AdapterConfigFileMap {
  file: string;
  type?: AdapterFileTypes;
}

export interface AdapterConfigMap {
  commands: { [keys in AdapterCommands]: AdapterConfigFileMap };
  other?: {
    [key: string]: AdapterFileTypes | string;
  };
}
