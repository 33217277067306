import {
  FaAngleDown as AngleDownIcon,
  FaAngleLeft as AngleLeftIcon,
  FaAngleRight as AngleRightIcon,
  FaBalanceScale as BalanceScaleIcon,
  FaBug as BugIcon,
  FaCaretDown as CaretDownIcon,
  FaCaretLeft as CaretLeftIcon,
  FaCaretRight as CaretRightIcon,
  FaCaretUp as CaretUpIcon,
  FaChartBar as BarChartIcon,
  FaCheck as CheckIcon,
  FaChevronCircleDown as ChevronCircleDownIcon,
  FaChevronCircleUp as ChevronCircleUpIcon,
  FaChevronLeft as ChevronLeftIcon,
  FaCircle as CircleIcon,
  FaCloudDownloadAlt as CloudDownload,
  FaCloudUploadAlt as CloudUpload,
  FaCog as CogIcon,
  FaCogs as CogsIcon,
  FaDownload as DownloadIcon,
  FaEdit as EditIcon,
  FaExclamationCircle as ExclamationCircle,
  FaExclamationTriangle as ExclamationTriangle,
  FaExpandArrowsAlt as FullScreenIcon,
  FaExternalLinkAlt as ExternalLinkIcon,
  FaEye as EyeIcon,
  FaEyeSlash as EyeSlashIcon,
  FaFile as FileIcon,
  FaFileAlt as FileAltIcon,
  FaFileCode as CodeFileIcon,
  FaFilter as FilterIcon,
  FaGithub as GithubIcon,
  FaGoogle as GoogleIcon,
  FaHtml5 as HTML5Icon,
  FaLongArrowAltLeft as LongArrowLeftIcon,
  FaMinus as MinusIcon,
  FaPaintBrush as PaintBrushIcon,
  FaPencilAlt as PencilIcon,
  FaPlay as PlayIcon,
  FaPlus as PlusIcon,
  FaPlusCircle as PlusCircleIcon,
  FaQuestion as QuestionIcon,
  FaQuestionCircle as QuestionCircleIcon,
  FaRegCheckCircle as CheckCircleIcon,
  FaRegCircle as CircleOIcon,
  FaRegEye as ToggleEye,
  FaRegEyeSlash as ToggleEyeOff,
  FaRegSave as SaveIcon,
  FaRegWindowMaximize as PaneIcon,
  FaSearch as SearchIcon,
  FaServer as ServerIcon,
  FaStar as Star,
  FaStop as StopIcon,
  FaTrash as TrashIcon,
  FaUpload as UploadIcon,
  FaWrench as WrenchIcon,
  FaTimes as TimesIcon,
  FaAmazon as AmazonIcon,
  FaArrowRight as RightArrowIcon,
} from 'react-icons/fa';
import { GoClippy as CopyIcon, GoGitBranch as BranchIcon } from 'react-icons/go';
import { IoLogoCss3 as CSSIcon, IoLogoJavascript as JSIcon, IoMdMove as MoveIcon } from 'react-icons/io';
import {
  MdComputer as DesktopIcon,
  MdDone as DoneIcon,
  MdEmail as EmailIcon,
  MdKeyboardReturn as KeyboardReturnIcon,
  MdMenu as MenuIcon,
  MdMoreHoriz as EllipsisHorizontalIcon,
  MdMoreVert as EllipsisVerticalIcon,
  MdRefresh as ResetIcon,
  MdReorder as ReorderIcon,
  MdReportProblem as ReportProblemIcon,
  MdSend as SendIcon,
  MdSmartphone as SmartPhoneIcon,
  MdSms as SMSIcon,
  MdTablet as TabletIcon,
  MdHttp as HTTPIcon,
} from 'react-icons/md';
import { TiClipboard as ClipboardIcon, TiExport as ExportIcon } from 'react-icons/ti';
import { GrConnect as ConnectIcon } from 'react-icons/gr';
import { BsTerminal as TerminalIcon } from 'react-icons/bs';

export {
  PlusCircleIcon,
  PlusIcon,
  MinusIcon,
  DownloadIcon,
  UploadIcon,
  ResetIcon as RefreshIcon,
  FilterIcon,
  CaretLeftIcon,
  CaretRightIcon,
  CaretDownIcon,
  CaretUpIcon,
  CloudDownload,
  CloudUpload,
  GithubIcon,
  ExternalLinkIcon,
  ExportIcon,
  SearchIcon,
  TrashIcon,
  GoogleIcon,
  PlayIcon,
  StopIcon,
  ResetIcon,
  CodeFileIcon,
  QuestionCircleIcon,
  QuestionIcon,
  KeyboardReturnIcon,
  SMSIcon,
  EmailIcon,
  ClipboardIcon,
  AngleDownIcon,
  WrenchIcon,
  PaintBrushIcon,
  FileIcon,
  FileAltIcon,
  EllipsisVerticalIcon,
  ServerIcon,
  JSIcon,
  CSSIcon,
  ReorderIcon,
  AngleLeftIcon,
  AngleRightIcon,
  FullScreenIcon,
  CopyIcon,
  CircleOIcon,
  CheckCircleIcon,
  EditIcon,
  PencilIcon,
  EyeIcon,
  EyeSlashIcon,
  ChevronCircleDownIcon,
  ChevronCircleUpIcon,
  CheckIcon,
  SaveIcon,
  EllipsisHorizontalIcon,
  LongArrowLeftIcon,
  BalanceScaleIcon,
  BarChartIcon,
  CogIcon,
  ExclamationTriangle,
  ExclamationCircle,
  Star,
  MenuIcon,
  ChevronLeftIcon,
  SmartPhoneIcon,
  TabletIcon,
  DesktopIcon,
  PaneIcon,
  ToggleEye,
  ToggleEyeOff,
  HTML5Icon,
  MoveIcon,
  BugIcon,
  BranchIcon,
  SendIcon,
  DoneIcon,
  ReportProblemIcon,
  CircleIcon,
  CogsIcon,
  TimesIcon,
  AmazonIcon,
  RightArrowIcon,
  HTTPIcon,
  ConnectIcon,
  TerminalIcon,
};
