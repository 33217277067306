import { IClearBlade } from 'clearblade-js-client';
import { sanitize } from 'dompurify';
import { NotificationObject } from 'react-notification';
import { AppStore } from 'reducers';
import { Store } from 'redux';
import { format } from 'utils/friendlyTime';
import { DatasourceDefinition, WidgetDefinition } from 'utils/types';
import { cb } from './cbLib';
import { Map } from './console-entity-models';
import Loader, { LoaderInterface } from './loader';
import DatasourceModel from './models/datasource/DatasourceModel';
import PortalModel, { paneHandler } from './models/PortalModel';

declare global {
  interface Window {
    CB_PORTAL: CbPortalInterface;
    portalTitle: string;
    store: Store<{}>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intl: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jQuery: any;
    datasources: Map<DatasourceModel>;
    panes: ProxyHandler<typeof paneHandler>;
  }
}

interface CbPortalInterface {
  registerDatasource: (def: DatasourceDefinition) => void;
  registerWidget: (def: WidgetDefinition) => void;
  selectPage: (path: string) => void;
  getPathParams: () => void;
  toggleFlyout: () => void;
  Modals: {
    open: (modalName: string) => void;
    close: (modalName: string) => void;
    list: () => string[];
  };
  ClearBlade: IClearBlade;
  Loader: LoaderInterface;
  portalModel: PortalModel;
  createNotification: (notification: Partial<NotificationObject>) => void;
  Utils: {
    sanitize: (str: string, opts: { ALLOWED_TAGS: string[] }) => string;
    formatDate: (date: string | Date, formatStr: string) => string;
  };
}

let CB_PORTAL: CbPortalInterface;
const configureCbPortalInterface = (store: AppStore) => {
  const portalModel = PortalModel.getInstance();
  portalModel.registerStore(store);
  const daInterface = {
    registerDatasource: portalModel.registerDatasource.bind(portalModel),
    registerWidget: portalModel.registerWidget.bind(portalModel),
    selectPage: portalModel.selectPage.bind(portalModel),
    getPathParams: portalModel.getPathParams.bind(portalModel),
    toggleFlyout: portalModel.toggleFlyout.bind(portalModel),
    Modals: {
      open: portalModel.Modals.open.bind(portalModel),
      close: portalModel.Modals.close.bind(portalModel),
      list: portalModel.Modals.list.bind(portalModel),
    },
    ClearBlade: cb,
    Loader,
    portalModel,
    Utils: {
      sanitize: (str: string, opts = { ALLOWED_TAGS: [] as string[] }) => sanitize(str, opts),
      formatDate: format,
    },
    createNotification: portalModel.createNotification.bind(portalModel),
  };

  CB_PORTAL = daInterface;
  window.CB_PORTAL = CB_PORTAL;
  window.datasources = {};
  window.panes = {};
  return portalModel;
};

export { CB_PORTAL, CbPortalInterface as ICbPortalInterface };

export default configureCbPortalInterface;
