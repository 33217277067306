import { StyleVars } from 'cssOverrides/styleVarsTypes';
import brandLogoUrl from 'img/brand-logos/rheem.png';
import defaultStyles from '../default/styleVars';

const $white = '#ffffff';
const $black = '#000000';
const $grey = '#458b8f';
const $red_color = '#e40c39';

const $primary = $red_color;
const $secondary = $grey;
const $danger = '#b50500';

const $header_background = $white;
const $header_text_color = $black;

const $loginButton = $red_color;
const $loginButtonFontColor = $white;
const $mainContainerFontColor = $white;
const $registrationFormWrapper = $white;
const $registrationFormHeaderFontColor = $black;
const $submitButton = $red_color;
const $submitButtonFontColor = $white;
const $platformOverviewContainer = $black;
const $platformOverviewFontColor = $white;
const $platformOverviewServicesFontColor = $red_color;
const $platformOverviewOutlineButton = $black;
const $platformOverviewOutlineButtonBorder = $red_color;
const $platformOverviewOutlineButtonFontColor = $red_color;
const $platformOverviewButton = $red_color;
const $platformOverviewButtonFontColor = $black;
const $ipmOverviewContainer = '#f0f0f';
const $ipmOverviewButton = $red_color;
const $ipmOverviewButtonFontColor = $white;
const $developerResourcesContainer = $black;
const $developerResourcesFontColor = $white;
const $developerResourcesServicesFontColor = $red_color;
const $developerResourcesButton = $red_color;
const $developerResourcesButtonFontColor = $black;

const styles: StyleVars = {
  ...defaultStyles,
  brandLogoUrl,
  brandLogoWhiteUrl: brandLogoUrl,
  displayPoweredByClearBlade: false,
  productName: 'ClearBlade',

  primary: $primary,
  secondary: $secondary,
  danger: $danger,

  header_background: $header_background,
  header_text_color: $header_text_color,

  landing_page_loginButton: $loginButton,
  landing_page_loginButtonFontColor: $loginButtonFontColor,
  landing_page_mainContainerFontColor: $mainContainerFontColor,
  landing_page_registrationFormWrapper: $registrationFormWrapper,
  landing_page_registrationFormHeaderFontColor: $registrationFormHeaderFontColor,
  landing_page_submitButton: $submitButton,
  landing_page_submitButtonFontColor: $submitButtonFontColor,
  landing_page_platformOverviewContainer: $platformOverviewContainer,
  landing_page_platformOverviewFontColor: $platformOverviewFontColor,
  landing_page_platformOverviewServicesFontColor: $platformOverviewServicesFontColor,
  landing_page_platformOverviewOutlineButton: $platformOverviewOutlineButton,
  landing_page_platformOverviewOutlineButtonBorder: $platformOverviewOutlineButtonBorder,
  landing_page_platformOverviewOutlineButtonFontColor: $platformOverviewOutlineButtonFontColor,
  landing_page_platformOverviewButton: $platformOverviewButton,
  landing_page_platformOverviewButtonFontColor: $platformOverviewButtonFontColor,
  landing_page_ipmOverviewContainer: $ipmOverviewContainer,
  landing_page_ipmOverviewButton: $ipmOverviewButton,
  landing_page_ipmOverviewButtonFontColor: $ipmOverviewButtonFontColor,
  landing_page_developerResourcesContainer: $developerResourcesContainer,
  landing_page_developerResourcesFontColor: $developerResourcesFontColor,
  landing_page_developerResourcesServicesFontColor: $developerResourcesServicesFontColor,
  landing_page_developerResourcesButton: $developerResourcesButton,
  landing_page_developerResourcesButtonFontColor: $developerResourcesButtonFontColor,
};

export default styles;
