import { getPlatformInfo } from 'cb-utils/platformInfo';
import { QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query';
import { GetQueryFnData } from 'utils/getQueryFnData';

const oidcCallbackQueryKeys = {
  all: (params: { code?: string; state?: string }) => [{ scope: 'oidc-callback', params }] as const,
};

async function callOIDCCallback({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof oidcCallbackQueryKeys.all>>): Promise<Record<string, unknown>> {
  const [
    {
      params: { code, state },
    },
  ] = queryKey;
  const platformInfo = getPlatformInfo();
  const searchParams = new URLSearchParams();
  code && state
    ? (searchParams.set('code', code), searchParams.set('state', state))
    : (searchParams.set('code', 'UNDEFINED'), searchParams.set('state', 'UNDEFINED'));
  const res = await fetch(`${platformInfo.url}/admin/oidc/callback?${searchParams.toString()}`, {
    method: 'GET',
  });
  if (!res.ok) {
    const text = await res.text();
    throw new Error(text);
  }

  const json = await res.json();
  return json;
}

type QueryFnData = GetQueryFnData<typeof callOIDCCallback>;
export const useOIDCCallbackQuery = <TQueryData = QueryFnData>(
  { code, state }: { code?: string; state?: string },
  options?: UseQueryOptions<QueryFnData, unknown, TQueryData, ReturnType<typeof oidcCallbackQueryKeys.all>>,
) => {
  return useQuery({
    ...options,
    enabled: typeof code !== 'undefined' && typeof state !== 'undefined',
    queryFn: callOIDCCallback,
    queryKey: oidcCallbackQueryKeys.all({ code, state }),
    refetchOnWindowFocus: false,
    retry: false,
  });
};
