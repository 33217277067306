/*
 * ErrorBoundary Messages
 *
 * This contains all the text for the ErrorBoundary component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  unkownError: {
    id: 'app.components.ErrorBoundary.unkownError',
    defaultMessage: 'Something went wrong in this widget. Check JS console for more info',
  },
});
