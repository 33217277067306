import { OneEntity } from 'cb-utils/console-entity-models';

export enum TwoFactorAuthMethods {
  SMS = 'sms',
  EMAIL = 'email',
  EMAIL_AND_SMS = 'email_sms',
}

export interface DevUserModel extends BaseDevUserModel {
  creation_date: number;
  last_login: number;
}

export interface BaseDevUserModel {
  admin: boolean;
  email: string;
  email_validated: boolean;
  fname: string;
  lname: string;
  org: string;
  phone: string;
  phone_validated: boolean;
  two_factor_enabled: boolean;
  two_factor_enabled_instance: boolean;
  two_factor_method: TwoFactorAuthMethods;
  userid: string;
}

interface StringKeyed {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface EdgeTokenInfo extends StringKeyed {
  name: string;
  devToken: string;
  systemKey: string;
}

export interface CurrentUserSlice extends OneEntity<DevUserModel> {
  isProxying: boolean;
  proxyError: string;
  isChangingPassword: boolean;
  changePasswordOpen: boolean;
}
