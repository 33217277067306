/**
 *
 * Logo
 *
 */

import ConsoleRoutes from 'containers/Console/routes';
import styleVars from 'cssOverrides/styleVars';
import React, { SFC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface LogoProps {
  url: string;
  backgroundPosition?: string;
}

const LogoImg = styled.div<LogoProps>`
  display: block;
  position: relative;
  min-width: 350px;
  margin: 0px;
  height: 50px;
  background-position: ${(props) => (props.backgroundPosition ? props.backgroundPosition : 'initial')};
  background-image: url('${(props) => props.url}');
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 0.5rem;
  margin-bottom: ${styleVars.displayPoweredByClearBlade ? '1rem' : 0};
  &:after {
    content: '${styleVars.displayPoweredByClearBlade ? 'Powered by ClearBlade' : ''}';
    color: ${styleVars.header_text_color};
    font-size: 10px;
    position: absolute;
    bottom: -1rem;
  }
`;

const Logo: SFC<{ className?: string } & LogoProps> = ({ className, url, backgroundPosition }) => {
  return (
    <Link className={className} to={ConsoleRoutes.SYSTEM_OVERVIEW}>
      <LogoImg className="navbar-brand" url={url} backgroundPosition={backgroundPosition} />
    </Link>
  );
};

export default Logo;
